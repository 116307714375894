<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <HeaderVue />

  <router-view />
  <FooterVue />
  <div class="stars"></div>
</template>
<script>
import FooterVue from './components/Footer.vue';
import HeaderVue from './components/Header.vue'
// import MainContainer from './components/MainContainer.vue'

export default {
  name: 'App',
  components: {
    HeaderVue,
    // MainContainer,
    FooterVue,
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Satisfy&display=swap');

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

html {
  overflow-x: hidden;
}

body {
  background-color: #000;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  box-sizing: border-box;
  color: #ffffff;
  padding: 20px;
  margin: 0;
  line-height: 1.6;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  transition: opacity .1s;
  overflow-x: hidden;

  @media (min-width: 768px) {
    padding: 20px 50px 50px;
    max-height: 100vh;
  }
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  letter-spacing: 1px;
}


.popup-opened {
  opacity: .5;
}

.font-alt {
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
  display: block;
  font-size: 24px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 36px;
    margin-bottom: 40px;
  }
}

.moving-img {
  --f: .1;
  /* radius */
  --_f: calc(100%*var(--f)/(1 + var(--f)));
  --_a: calc(90deg*var(--f));
  max-height: 100%;
  width: auto;
  max-width: 100%;
  aspect-ratio: calc(1 + var(--f));
  clip-path: inset(0 var(--_f) 0 0 round);
  transform: none;
  transition: .5s;
  cursor: pointer;
  will-change: transform;
}

.moving-img:hover {
  clip-path: inset(0 0 0 var(--_f) round);
  --_t: translateX(calc(-1*var(--_f))) rotateY(calc(-1*var(--_a)));
  transform: perspective(200px) var(--_t, rotateY(var(--_a)));
}

.stars {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #000 url("https://i.imgur.com/YKY28eT.png") repeat top center;
}
</style>