<template>
    <PopupContainer :full-width="true" ref="popupContainer" id="aboutUs" type="imageDescription" :topImage="{
        src: 'ptak.png'
    }" name="NOE">
        <div class="about-us__text"><span class="about-us__headline font-alt">Nocna Orkiestra Eksperymentalna -
                NOE</span>
            to zespół powstały
            w 2022 z inicjatywy Piotra
            Kotasa, pianisty i
            kompozytora.
            Tworzy go obecnie 23 młodych, pełnych nieskrępowanego zapału i nieobliczalnych pomysłów muzyków ze Śląska,
            głównie z Rybnika.
            Orkiestra swoją działalność kierunkuje w stronę wykonywania muzyki najnowszej, pozyskując i opracowując nowe
            kompozycję.<br><br>
            Nie jest to jednak zespół z kręgu tzw muzyki poważnej.<br>
            Wręcz przeciwnie. Niepoważność to nasz cel i środek do celu.<br>
            Bawimy się grając i bawimy Was bawiąc się.<br><br>
            Nie znosimy jednak kompromisów. Nasza muzyka jest intensywna nieprzywidywalna i ambitna, a mimo to jesteśmy
            przekonani że nie oprzesz się jej urokowi i nieudolnie skrywanej ironi.
            Choć krytyka nie znosi takich nieposortowanych odmieńców, jesteśmy z siebie dumni i napełnieni pewnością
            swej
            wartości biegniemy na spotkanie z Tobą.
            Średnia wieku członków zespołu nie ma znaczenia.</div>
        <SocialMedia />
    </PopupContainer>
</template>
<script>

import PopupContainer from './PopupContainer.vue'
import SocialMedia from './SocialMedia.vue'

export default {
    name: 'AboutUs',
    components: {
        PopupContainer,
        SocialMedia
    },
    methods: {
        open() {
            this.$refs.popupContainer.open()
        }
    }
}
</script>
<style scoped lang="scss">
.about-us__text {
    text-transform: none;
}
</style>