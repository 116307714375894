<template>
    <div class="info-box-content">
        <h2 class="info-box-content__headline font-alt">Nocna płyta</h2>
        <div class="info-box-content__description">
            <a href="https://allegrolokalnie.pl/oferta/nocna-plyta-nocna-orkiestra-eksperymentalna" target="_blank">
                <img class="info-box-content__main-image" :src="getImage('okladka.png')"
                    alt="Nocna Orkiestra Eksperymentalna" :title="`Nocna Orkiestra Eksperymentalna, Nocna Płyta`"></a>


            <div class="info-box-content__description-text"> Debiutancka płyta NOE, płytę kupicie na <a
                    href="https://allegrolokalnie.pl/oferta/nocna-plyta-nocna-orkiestra-eksperymentalna"
                    target="_blank">Allegro tutaj</a>
                lub lokalnie w Halo Rybnik i Księgarnia Orbita. </div>
        </div>

        <SocialMedia />

    </div>
</template>
<script>

import SocialMedia from './SocialMedia.vue'
export default {
    name: 'InfoBoxContent',
    components: {
        SocialMedia
    },
    methods: {
        getImage(src) {
            return require(`../assets/${src}`);
        }
    }
}
</script>
<style scoped lang="scss">
.info-box-content {
    text-transform: none;

    &__headline {
        text-align: center;
    }

    &__description {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    &__main-image {
        max-width: 200px;

        @media (min-width: 768px) {
            max-width: 250px;
        }
    }

    &__description-text {
        font-size: 18px;
        line-height: 1.5;
        max-width: 400px;

        @media (min-width: 768px) {
            padding: 0 20px;
        }

        a {
            color: #9e7683;
        }
    }
}
</style>
  