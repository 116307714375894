<template>
    <div class="main-container">
        <div class="main-container__wrapper">
            <div class="main-container__people-list">
                <PersonWithMoon class="main-container__people-list-item" @click="openModal(person)" v-for="person in people"
                    :key="person.id" :name="person.name" :src="person.topImage.src" />
            </div>
            <GramophoneVue class="main-container__gramophone" />
        </div>
        <PopupContainer keep-small v-bind="popupData" type="imageDescription" @close="closeModal" ref="popupContainer" />
    </div>
</template>
<script>
import peopleDescription from '../composables/peopleDescription.js'
import PersonWithMoon from './PersonWithMoon.vue'
import PopupContainer from './PopupContainer.vue'
import GramophoneVue from './Gramophone.vue'

export default {
    name: 'MainContainer',
    components: {
        PopupContainer,
        PersonWithMoon,
        GramophoneVue
    },
    data() {
        return {
            people: peopleDescription(),
            popupData: {}
        }
    },
    methods: {
        openModal(personData) {
            this.popupData = personData
            this.$refs.popupContainer.open()
        },
        closeModal() {
            this.popupData = {}
        }
    }
}
</script>


<style scoped lang="scss">
.main-container {
    position: relative;
    max-width: 1470px;
    margin: 0 auto;
    z-index: 2;
    min-height: 400px;

    @media (min-width: 1024px) {
        max-width: 500px;
        min-height: 600px;
    }
}

.main-container__gramophone {
    display: flex;
    justify-content: center;
    z-index: 2;
    position: absolute;
}

$n: 23;
$item-separation: 60px;
$theta: calc(2 * 3.14 / $n);
$apothem: 600px;
$apothemLg: 800px;

.main-container__wrapper {
    perspective: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1024px) {
        perspective: 800px;
    }

}

.main-container__people-list {
    margin: 0;
    width: 240px;
    transform-style: preserve-3d;
    transition: transform .5s;
    transform-origin: 50% 50% (-($apothem));
    animation: carousel 120s linear infinite;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    z-index: 1;
    top: 250px;
    position: relative;

    @media (min-width: 1024px) {
        top: 470px;
        transform-origin: 50% 50% (-($apothemLg));
    }
}

.main-container__people-list-item {
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0 calc($item-separation / 2);

    &:not(:first-of-type) {
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: 50% 50% (-($apothem));

        @media (min-width: 1024px) {
            transform-origin: 50% 50% (-($apothemLg));
        }
    }

    @for $i from 2 through $n {
        &:nth-child(#{$i}) {
            transform: rotateY(#{($i - 1) * $theta}rad);
        }
    }
}

@keyframes carousel {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}
</style>