<template>
    <div class="future-event">
        <span class="future-event__headline font-alt"> <span class="future-event__text">Już wkrótce w Twoim
                mieście...</span>
        </span>
        <!-- <span class="future-event__text">Coming soon...</span> -->
        <!-- 
        <span class="future-event__link"><a href="https://biletyna.pl/event/view/id/398535" target="_blank">Bilety do
                kupienia online tutaj!</a></span> -->
        <div class="future-event__content">
            <div class="future-event__poster">
                <img src="../assets/elementy_NOE_3.jpeg" alt="Nocna Orkiestra Eksperymentalna Space Night Show"
                    title="Nocna Orkiestra Eksperymentalna Space Night Show" loading="lazy">
            </div>
            <div class="future-event__contact">
                <h2 class="upcoming-events-content__headline font-alt">Kontakt</h2>
                <a href="mailto:nocnaorkiestra@gmail.com">nocnaorkiestra@gmail.com</a><br><br>
                management & concert booking: tel. <a href="tel:+48664764987">664 764 987</a><br><br>
                <SocialMedia class="future-event__socials" />
            </div>
        </div>

    </div>
</template>
<script>
import SocialMedia from './SocialMedia.vue'
export default {
    name: 'FutureEvent',
    components: {
        SocialMedia
    },
}
</script>
<style scoped lang="scss">
.future-event {
    color: white;
    height: max-content;
    overflow-y: auto;

    &__link,
    &__text {
        text-align: center;
        display: block;
        color: #2397b4;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 40px;

        @media (min-width: 768px) {
            font-size: 24px;
        }
    }

    &__link {
        text-decoration: underline;
    }

    &__headline {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__socials {
        margin-top: auto;
    }

    &__poster {
        max-width: 90%;
        width: auto;
        margin: 0 auto;

        @media (min-width: 768px) {
            max-width: 400px;
        }

        img {
            width: auto;
            max-width: 100%;
        }
    }

    &__contact {
        padding: 20px;
        font-size: 14px;

        @media (min-width: 768px) {
            font-size: 18px;
            padding: 40px;
        }
    }
}
</style>