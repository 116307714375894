<template>
    <div class="sponsors">
        <div class="sponsors__item" v-for="sponsor in sponsors" :key="sponsor.id">
            <img class="sponsors__main-image" :src="getImage(sponsor.img)" alt="Nocna Orkiestra Eksperymentalna"
                :title="`Nocna Orkiestra Eksperymentalna, Nocna Płyta, ${sponsor.name}`">
            <p class="sponsors__text" v-if="sponsor.text">{{ sponsor.text }}</p>
        </div>

    </div>
</template>
<script>

import sponsors from '../composables/sponsors.js'
export default {
    name: 'SponsorsVue',
    data() {
        return {
            sponsors: sponsors()
        }
    },
    methods: {
        getImage(src) {
            return require(`../assets/${src}`);
        }
    }
}
</script>
<style scoped lang="scss">
.sponsors {
    padding: 40px 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    &__item {
        width: 100%;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: #d9bb6d;

        @media (min-width: 768px) {
            max-width: 30%;
        }

        img {
            width: 200px;
        }
    }

    &__text {
        text-align: center;
        color: #0e0e0e;
        font-size: 18px;
        font-weight: 600;
        margin-top: 30px;
    }
}
</style>