<template>
  <header class="header">
    <BurgerMenu />
    <div class="header__logo " @click="showAboutUs">
      <ImageHover src="logo.png" src-hover="logo_light.png" />
    </div>
    <AboutUs ref="aboutUs" />
  </header>
</template>

<script>
import BurgerMenu from './BurgerMenu.vue'
import AboutUs from './AboutUs.vue'
import ImageHover from './ImageHover.vue'

export default {
  name: 'HeaderVue',
  components: {
    BurgerMenu,
    AboutUs,
    ImageHover
  },
  methods: {
    showAboutUs() {
      this.$router.push({ path: '/' })
      this.$refs.aboutUs.open()
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: auto;
  max-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media (min-width: 1024px) {
    max-height: 250px;
  }
}

.header__logo {
  width: 150px;
  margin-left: auto;
  top: 0;
  right: -20px;
  cursor: pointer;
  position: absolute;
  z-index: 20;

  @media (min-width: 768px) {
    width: 300px;
    top: 0;
    right: -50px;
  }

  @media (min-width: 1024px) {
    right: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}
</style>
