export default function sponsors() {
    return [{
        id: 'alansystems',
        name: 'Alan Systems',
        text: 'Sponsor strategiczny',
        img: 'alansystems.png'
    },
    {
        id: 'miastorybnik',
        name: 'Miasto Rybnik',
        img: 'miastorybnik.png'
    },
    {
        id: 'voucherify',
        name: 'voucherify',
        img: 'voucherify.png'
    }]
}