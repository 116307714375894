<template>
    <div class="social-media">
        <a href="https://www.facebook.com/nocnaorkiestra" target="_blank">
            <img class="info-box-content__main-image" :src="getImage('facebook.png')"
                alt="Nocna Orkiestra Eksperymentalna Facebook" :title="`Nocna Orkiestra Eksperymentalna, Nocna Płyta`">
        </a>

        <a href="https://www.youtube.com/@nocnaorkiestra" target="_blank">
            <img class="info-box-content__main-image" :src="getImage('youtube.png')"
                alt="Nocna Orkiestra Eksperymentalna Youtube" :title="`Nocna Orkiestra Eksperymentalna, Nocna Płyta`">
        </a>
    </div>
</template>
<script>
export default {
    name: 'SocialMedia',
    methods: {
        getImage(src) {
            return require(`../assets/${src}`);
        }
    }
}
</script>
<style scoped lang="scss">
.social-media {
    padding: 20px;
    display: flex;
    justify-content: center;

    a {
        max-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        padding: 10px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.8);
    }

    img {
        width: 100%;
    }
}
</style>