<template>
    <img class="person-with-moon__front" :src="getImage(src)" :alt="`Nocna Orkiestra Eksperymentalna ${name}`"
        :title="`Nocna Orkiestra Eksperymentalna ${name}`">
</template>
<script>

export default {
    name: 'PersonWithMoon',
    props: {
        name: {
            type: String
        },
        src: {
            type: String
        },
    },
    methods: {
        getImage(src) {
            return require(`../assets/${src}`);
        }
    }
}
</script>

  