<template>
    <iframe style="border-radius:12px"
        src="https://open.spotify.com/embed/artist/3TivGJux46t67WSpKJwf2J?utm_source=generator" width="100%" height="352"
        frameBorder="0" allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
</template>
<script>

export default {
    name: 'SpotifyIframe'
}
</script>