export default function peopleDescription() {
    return [{
        id: 'alicjaHartmann',
        name: 'Alicja Hartmann',
        topImage: {
            src: 'alicjaHartmann.png'
        },
        bottomImage: {
            src: 'alicjaHartmann_signature.png'
        },
        text: 'lubi rządzić, ale nie lubi jak ktoś rządzi nią, dlatego założyła własną firmę, gdzie uczy z pasją gry na skrzypcach, gra w NOE, gdzie podstawą jest wolność muzykowania i impowizacja, co bardzo jej odpowiada.'
    }, {
        id: 'arturMotyka',
        name: 'Artur Arti Motyka',
        topImage: {
            src: 'arturMotyka.png'
        },
        bottomImage: {
            src: 'arturMotyka_signature.png'
        },
        text: 'saksofonista, który pomimo wykształcenia tzw. poważnego lubi grać muzykę mniej poważną. Ojciec - nauczyciel  - wykładowca. Amen.'
    }, {
        id: 'emiliaPioro',
        name: 'Emilia Pióro',
        topImage: {
            src: 'emiliaPioro.png'
        },
        bottomImage: {
            src: 'emiliaPioro_signature.png'
        },
        text: 'Z zamiłowania skrzypaczka, z potrzeby - miłośniczka kawy. Interesują ją górskie wędrówki, dalekie podróże i perkusista NOE - Bartek'
    }, {
        id: 'piotrKucharz',
        name: 'Piotr Kucharz',
        topImage: {
            src: 'piotrKucharz.png'
        },
        bottomImage: {
            src: 'piotrKucharz_signature.png'
        },
        text: '.kapsw eibos o ękton ćasipan łaichc ezswaZ. atsisabartnok utsorp oP'
    }, {
        id: 'martaPlewka',
        name: 'Marta Plewka',
        topImage: {
            src: 'martaPlewka.png'
        },
        bottomImage: {
            src: 'martaPlewka_signature.png'
        },
        text: 'w skrzypcach ceni sobie granie „drużynowe”; na co dzień preferuje tradycyjne zespoły (o unormowanych godzinach pracy), ale z wieczora bardziej „eksperymentalne” grupy. Najlepiej, kiedy z takim zespołem można zwiedzać świat…'
    }, {
        id: 'dorotaBenisz',
        name: 'Dorota Benisz',
        topImage: {
            src: 'dorotaBenisz.png'
        },
        bottomImage: {
            src: 'dorotaBenisz_signature.png'
        },
        text: 'poplątanie na głowie i w głowie stara się wyprostować grą na klarnecie'
    }, {
        id: 'barbaraBytner',
        name: 'Barbara Bytner',
        topImage: {
            src: 'barbaraBytner.png'
        },
        bottomImage: {
            src: 'barbaraBytner_signature.png'
        },
        text: 'Skrzypaczka, nauczycielka, mistrz organizacji, pasjonatka rozporządzeń i zarządzeń. Jako mama dwójki dzieci ma mnóstwo czasu (i ogromną potrzebę) na rozwijanie muzycznych umiejętności, szczególnie w nocnej orkiestrze.'
    }, {
        id: 'adamDzierzega',
        name: 'Adam Dzierżęga',
        topImage: {
            src: 'adamDzierzega.png'
        },
        bottomImage: {
            src: 'adamDzierzega_signature.png'
        },
        text: 'Pianista, zamiast ćwiczenia wybrał jazz. Nieustannie walczy zarówno z czarnymi, jak i białymi… [klawiszami]. W kobietach docenia przede wszystkim dobrze wyczuwalny puls. Nałogowo kurzy dziadkerską fajkę.'
    }, {
        id: 'annaBrachman',
        name: 'Anna Brachman',
        topImage: {
            src: 'annaBrachman.png'
        },
        bottomImage: {
            src: 'annaBrachman_signature.png'
        },
        text: 'Pianistka, pedagog, lubi nuty. Kocha swoją pracę, ale wygląda w niej mało profesjonalnie, przyjeżdża na rowerze i naucza w ulubionych bluzach z kapturem. Wrzuca euraska wszystkim ulicznym grajkom (nie symulantom) podczas licznych podróży.'
    }, {
        id: 'barbaraStawiarska',
        name: 'Barbara Stawiarska',
        topImage: {
            src: 'barbaraStawiarska.png'
        },
        bottomImage: {
            src: 'barbaraStawiarska_signature.png'
        },
        text: 'Związana z operetką, choć oryginalne postaci, wysublimowane stroje i żarty zachwycają ją nie tylko w sztuce. Ćwierka na różnych patyczkach to tu, to tam... Staccato owszem, ale straciatella bardziej owszem.'
    }, {
        id: 'esteraManderla',
        name: 'Estera Manderla',
        topImage: {
            src: 'esteraManderla.png'
        },
        bottomImage: {
            src: 'esteraManderla_signature.png'
        },
        text: 'Osoba, która do składu orkiestry wniosła harmonię. Z zawodu dyrektor, z zamiłowania nauczycielka i akordeonistka. Mało sypia, dlatego chętnie objęła kolejny etat - jako muzyk nocnej orkiestry.'
    }, {
        id: 'piotrKotas',
        name: 'Piotr Kotas',
        topImage: {
            src: 'piotrKotas.png'
        },
        bottomImage: {
            src: 'piotrKotas_signature.png'
        },
        text: 'Główny kwestionariusz, honorowy dawca nut. Ceglarz, z lenistwa keyboardzista, batutmistrz. Usposobienie raczej znośne. Zaraża.'
    }, {
        id: 'dominikMarszalek',
        name: 'Dominik Marszałek',
        topImage: {
            src: 'dominikMarszalek.png'
        },
        bottomImage: {
            src: 'dominikMarszalek_signature.png'
        },
        text: 'Zawodowo bierze długie i czarne do buzi, choć szlachetniej mówiąc: gra na oboju. Pasjonat dobrego kina, którego ostatnimi czasy doświadcza głównie podczas licznych podróży. '
    }, {
        id: 'justynaDzierbicka',
        name: 'Justyna Dzierbicka-Paryl',
        topImage: {
            src: 'justynaDzierbicka.png'
        },
        bottomImage: {
            src: 'justynaDzierbicka_signature.png'
        },
        text: 'Młoda kobieta przed osiągnięciem wieku emerytalnego, żeński generator skrzypcowych dźwięków, z pasji belferka, zaraża śmiechem, rzadziej grypą.'
    }, {
        id: 'wiktoriaHeryszek',
        name: 'Wiktoria Heryszek',
        topImage: {
            src: 'wiktoriaHeryszek.png'
        },
        bottomImage: {
            src: 'wiktoriaHeryszek_signature.png'
        },
        text: 'Cecha charakterystyczna: blond włos, który wraz z właścicielką przemierzył już część świata, począwszy od Polski, na Australii kończąc. Na co dzień studentka akademii muzycznej, miłośniczka poezji śpiewanej, po zmroku zatraca się w eksperymentalnych melodiach nocnej orkiestry. '
    }, {
        id: 'michalBenisz',
        name: 'Michał Benisz',
        topImage: {
            src: 'michalBenisz.png'
        },
        bottomImage: {
            src: 'michalBenisz_signature.png'
        },
        text: 'trzeźwo myślący puzonista. Stara się unikać stresujących i konfliktowych sytuacji. Przestrzega przepisów kulinarnych - niekoniecznie drogowych.'
    }, {
        id: 'martynaPekala',
        name: 'Martyna Pękała',
        topImage: {
            src: 'martynaPekala.png'
        },
        bottomImage: {
            src: 'martynaPekala_signature.png'
        },
        text: 'Z wykształcenia muzyk, z zawodu handlowiec . Pogodzona z tym, że już nigdy nie zagra na scenie, schowała fagot w najgłębszych zakamarkach domu. Do czasu kiedy światło dzienne (a raczej nocne) ujrzała NOE. Fagot ponownie będzie mógł dumnie zaprezentować się w blasku fleszy. Martyna też.'
    }, {
        id: 'bartlomiejChlubek',
        name: 'Bartłomiej Chlubek',
        topImage: {
            src: 'bartlomiejChlubek.png'
        },
        bottomImage: {
            src: 'bartlomiejChlubek_signature.png'
        },
        text: 'Perkusista, miłośnik Fuzji (,) Rocka i Jazzu oraz perłowych bębnów. O imperium rzymskim rozmyślał na długo przed tym jak stało się to modne. Dołączenie do NOE nie było spowodowane jego sympatią do baranów. '
    }, {
        id: 'annaWieczorek',
        name: 'Anna Wieczorek (Talik)',
        topImage: {
            src: 'annaWieczorek.png'
        },
        bottomImage: {
            src: 'annaWieczorek_signature.png'
        },
        text: 'Osoba o wielu nazwiskach. Pseudonim artystyczny: Szarlot. Cechy charakterystyczne: ogień na scenie, nieposkromiona energia i szeroki uśmiech. Skrzypce nosi zawsze ze sobą.'
    }, {
        id: 'pawelSzklarski',
        name: 'Paweł Szklarski',
        topImage: {
            src: 'pawelSzklarski.png'
        },
        bottomImage: {
            src: 'pawelSzklarski_signature.png'
        },
        text: 'Za dnia poważny muzyk fagocista i pedagog, po godzinach pasjonat gier komputerowych, nowych technologii, eksperymentów dźwiękowych i biegania.'
    }, {
        id: 'paulinaKulej',
        name: 'Paulina Kulej',
        topImage: {
            src: 'paulinaKulej.png'
        },
        bottomImage: {
            src: 'paulinaKulej_signature.png'
        },
        text: 'głowa wysoko w chmurach, skoki rowerem po górach, bezgraniczna miłość do wiolonczeli, którą chętnie z uczniami i sceną się dzieli. Szczypta szaleństwa, akord radości, czasem połamane kości, sport w każdej ilości, do eksperymentów / eksploracji świata (dźwięków) zawsze w gotowości.'
    },
    {
        id: 'wojciechSwierczyna',
        name: 'Wojciech Świerczyna',
        topImage: {
            src: 'wojciechSwierczyna.png'
        },
        bottomImage: {
            src: 'wojciechSwierczyna_signature.png'
        },
        text: 'gitarzysta, menager i pedagog. Największy fan Bryana Adamsa. Nie wyobraża sobie życia bez gitary. Gra na tyle dużo, że wielu przypisuje mu dar bilokacji.'
    }, {
        id: 'dawidWita',
        name: 'Dawid Wita',
        topImage: {
            src: 'dawidWita.png'
        },
        bottomImage: {
            src: 'dawidWita_signature.png'
        },
        text: 'Uzależniony od kawy i sportu. W różne miejsca przychodzi z trąbką - jeszcze nikt się nie zorientował, że nie potrafi na niej grać.'
    },]
}