export default function infoDescription() {
    return [{
        id: 'infoBox',
        type: 'imageDescription',
        topImage: {
            src: 'taniec.png'
        },
    },
    {
        id: 'upcomingEvents',
        type: 'imageDescription',
        topImage: {
            src: 'organy.png'
        },
    },
    {
        id: 'contact',
        type: 'imageDescription',
        topImage: {
            src: 'trabka.png'
        },
        fullWidth: true
    },
    {
        id: 'futureEvent'
    }
    ]
}