<template>
    <div class="sidemenu">
        <button class="sidemenu__btn" v-on:click="navOpen = !navOpen" v-bind:class="{ 'active': navOpen }">
            <span class="top"></span>
            <span class="mid"></span>
            <span class="bottom"></span>
        </button>
        <span class="sidemenu__btn-menu" v-bind:class="{ 'sidemenu__btn-menu--hidden': navOpen }">Menu</span>
        <transition name="translateX">
            <nav v-show="navOpen">
                <div class="sidemenu__wrapper">
                    <ul class="sidemenu__list">
                        <li class="sidemenu__item"><router-link @mousedown="showAboutUs" class="sidemenu__item link"
                                to="/">O nas</router-link></li>
                        <li class="sidemenu__item" @mousedown="showInfo('upcomingEvents')">Wydarzenia</li>
                        <li class="sidemenu__item" @mousedown="showInfo('spotify')">Posłuchaj nas</li>
                        <li class="sidemenu__item" @mousedown="showInfo('infoBox')">Kup płytę</li>
                        <li class="sidemenu__item"><router-link @mousedown="completerouterHandler"
                                class="sidemenu__item link" to="/media">Media</router-link></li>
                        <li class="sidemenu__item" @mousedown="showInfo('sponsors')">Sponsorzy</li>
                        <li class="sidemenu__item" @mousedown="showInfo('contact')">Kontakt</li>
                        <li class="sidemenu__item">
                            <SocialMedia class="sidebar__socials" />
                        </li>
                    </ul>
                </div>
            </nav>
        </transition>
    </div>
    <AboutUs ref="aboutUs" />
    <PopupContainer v-bind="popupData" @close="closeModal" ref="popupContainer">
        <template v-if="popupData.id === 'spotify'">
            <SpotifyIframe />
        </template>
        <template v-if="popupData.id === 'infoBox'">
            <InfoBoxContent />
        </template>
        <template v-if="popupData.id === 'upcomingEvents'">
            <UpcomingEventsContent />
        </template>
        <template v-if="popupData.id === 'contact'">
            <ContactVue />
        </template>
        <template v-if="popupData.id === 'sponsors'">
            <SponsorsVue />
        </template>
    </PopupContainer>
</template>
<script>
import AboutUs from './AboutUs.vue'
import SponsorsVue from './Sponsors.vue'
import PopupContainer from './PopupContainer.vue'
import SpotifyIframe from './SpotifyIframe.vue'
import ContactVue from './Contact.vue'
import InfoBoxContent from './InfoBoxContent.vue'
import UpcomingEventsContent from './UpcomingEventsContent.vue'
import infoDescription from '../composables/infoDescription.js'
import SocialMedia from './SocialMedia.vue'

export default {
    name: 'BurgerMenu',
    components: {
        AboutUs,
        PopupContainer,
        SpotifyIframe,
        ContactVue,
        SponsorsVue,
        InfoBoxContent,
        UpcomingEventsContent,
        SocialMedia
    },
    data() {
        return {
            navOpen: false,
            popupData: {},
            infoDescription: infoDescription()
        }
    },
    methods: {
        closeNav() {
            this.navOpen = false;
        },
        showAboutUs() {
            this.$router.push({ path: '/' })
            this.closeNav()
            this.$refs.aboutUs.open()
        },
        showInfo(id) {
            this.closeNav()
            this.popupData = this.infoDescription.find(data => data.id === id) ?? { id: id, fullWidth: true }
            this.$refs.popupContainer.open()
        },
        closeModal() {
            this.popupData = {}
        },
        completerouterHandler() {
            this.$router.push({ path: '/media' })
            this.closeNav()
        }
    }
}
</script>

<style scoped lang="scss">
.burger-menu {
    cursor: pointer;
}


.sidemenu nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.9);

    @media (min-width: 600px) {
        width: 470px;
    }
}

.sidebar__socials {
    justify-content: flex-start;
    padding: 0;
}


.sidemenu .sidemenu__btn {
    display: block;
    padding: 0;
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    position: relative;
    z-index: 100;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;

    @media (min-width: 768px) {
        width: 80px;
        height: 80px;
    }
}

.sidemenu__btn-menu {
    text-transform: uppercase;
    color: white;
    width: 100%;
    text-align: center;
    display: block;
    opacity: 1;
    transition: opacity .2s;
    font-size: 10px;
    letter-spacing: 3px;
    font-weight: 600;

    @media (min-width: 768px) {
        letter-spacing: 5px;
        font-size: 14px;
    }

    &--hidden {
        opacity: 0;
    }
}


.sidemenu .sidemenu__btn span {
    display: block;
    width: 40px;
    height: 3px;
    margin: auto;
    background: #9e7683;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.4s ease;

    @media (min-width: 768px) {
        width: 80px;
    }
}

.sidemenu .sidemenu__btn span.top {
    transform: translateY(-8px);

    @media (min-width: 768px) {
        transform: translateY(-16px);
    }
}

.sidemenu .sidemenu__btn span.bottom {
    transform: translateY(8px);

    @media (min-width: 768px) {
        transform: translateY(16px);
    }
}

.sidemenu .sidemenu__btn.active .top {
    transform: rotate(-45deg);
}

.sidemenu .sidemenu__btn.active .mid {
    transform: translateX(-20px) rotate(360deg);
    opacity: 0;
}

.sidemenu .sidemenu__btn.active .bottom {
    transform: rotate(45deg);
}

.sidemenu__btn-menu .sidemenu .sidemenu__wrapper {
    padding-top: 60px;

    @media (min-width: 768px) {
        padding-top: 50px;
    }

}

.sidemenu .sidemenu__list {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 80px;

    @media (min-width: 768px) {
        padding-top: 120px;
    }
}

.sidemenu .sidemenu__item a,
.sidemenu .sidemenu__item {
    text-decoration: none;
    line-height: 1.5;
    font-size: 16px;
    padding: 20px;
    display: block;
    color: #9e7683;
    font-weight: 600;
    cursor: pointer;
    transition: 0.4s ease text-decoration;

    @media (min-width: 600px) {
        padding: 20px 50px;
        font-size: 24px;
    }
}

.sidemenu .sidemenu__item.link {
    padding: 0;
}

.sidemenu .sidemenu__item a:hover,
.sidemenu .sidemenu__item:hover {
    text-decoration: underline;
}

.translateX-enter {
    transform: translateX(-200px);
    opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
    transform-origin: top left 0;
    transition: 0.2s ease;
}

.translateX-leave-to {
    transform: translateX(-200px);
    opacity: 0;
}
</style>
