<template>
    <div class="gramophone">

        <div class="gramophone__audio-player" :class="{ 'gramophone__audio-player--on': isPlaying }">
            <AudioPlayer :audio-list="audioList()" :show-next-button="false" :show-prev-button="false"
                :show-progress-bar="false" :show-playback-rate="false" theme-color="#d9bb6d" :show-volume-button="false"
                @play="handleStartPaly" @pause="handleStopPlaying" />
        </div>
        <div @click="gotToMedia" class="gramophone__media moving-img"> <img class="gramophone__media-image"
                :src="getImage('camera-icon.svg')" alt="Nocna Orkiestra Eksperymentalna"
                :title="`Nocna Orkiestra Eksperymentalna NOE`"></div>
        <GramophoneSVG :class="{ 'gramophone__gramophone--on': isPlaying }"></GramophoneSVG>
    </div>
</template>
<script>
import GramophoneSVG from './GramophoneSVG.vue';
import AudioPlayer from '@liripeng/vue-audio-player'

export default {
    name: 'GramophoneVue',
    components: {
        GramophoneSVG,
        AudioPlayer
    },
    data() {
        return {
            isPlaying: false
        }
    },
    methods: {

        audioList() {
            return [
                require('@/assets/mp3/Industrial.mp3'), require('@/assets/mp3/Introductiontonext.mp3'), require('@/assets/mp3/Jezyki.mp3')
            ]
        },
        handleStartPaly() {
            this.isPlaying = true
        },
        handleStopPlaying() {
            this.isPlaying = false
        },
        gotToMedia() {
            this.$router.push({ path: '/media' })
            if (this.isPlaying) this.handleStopPlaying()
        },
        getImage(src) {
            return require(`../assets/${src}`);
        }
    }
}
</script>

<style lang="scss">
.gramophone {
    transform-box: fill-box;
    transform-origin: center;
    will-change: transform;
    animation: none;

    &__gramophone--on {
        animation: pulse 2s linear infinite;
    }
}

.gramophone__disc {
    /* animation: spin 5s linear infinite; */
    transform-box: fill-box;
    transform-origin: center;
    will-change: transform;
}


@keyframes spin {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}

@keyframes pulse {
    5% {
        transform: scale(1.01);
    }

    25% {
        transform: scale(1.08);
    }

    70% {
        transform: scale(1.12);
    }

    100% {
        transform: scale(1);
    }
}

.gramophone__media {

    position: absolute;
    cursor: pointer;
    width: 40px;
    z-index: 1;
    left: -20px;
    top: 0;
    border-radius: 50%;
    background: #9e7683;
    padding: 5px;

    @media (min-width: 768px) {
        left: -20px;
    }

    @media (min-width: 1024px) {
        left: 80px;
    }

    img {
        width: 100%;
        height: 100%;
        margin-top: 5px;
    }
}

.gramophone__audio-player {
    position: absolute;
    z-index: 1;
    right: -20px;
    top: 50px;

    @media (min-width: 768px) {
        right: -20px;
        top: 60px;
    }

    @media (min-width: 1024px) {
        right: 80px;
        top: 80px;
    }
}
</style>