<template>
    <footer class="footer">
        <div class="footer__banner" @click="openModal('infoBox')">
            <ImageHover src="nocna_plyta_logo.png" src-hover="nocna_plyta_logo_light.png" />
        </div>
        <div class="footer__banner" @click="openModal('futureEvent')">
            <ImageHover src="space_night_show.png" src-hover="space_night_show_light.png" />
        </div>
        <div class="footer__banner" @click="openModal('upcomingEvents')">
            <ImageHover src="women_with_microscope.png" src-hover="women_with_microscope_light.png" />
        </div>
        <PopupContainer v-bind="popupData" @close="closeModal" ref="popupContainer">

            <template v-if="popupData.id === 'infoBox'">
                <InfoBoxContent />
            </template>
            <template v-if="popupData.id === 'futureEvent'">
                <FutureEvent />
            </template>
            <template v-if="popupData.id === 'upcomingEvents'">
                <UpcomingEventsContent />
            </template>
        </PopupContainer>
    </footer>
</template>

<script>
import PopupContainer from './PopupContainer.vue'
import InfoBoxContent from './InfoBoxContent.vue'
import UpcomingEventsContent from './UpcomingEventsContent.vue'
import infoDescription from '../composables/infoDescription.js'
import FutureEvent from './FutureEvent.vue'
import ImageHover from './ImageHover.vue'

export default {
    name: 'FooterVue',
    components: {
        PopupContainer,
        InfoBoxContent,
        FutureEvent,
        UpcomingEventsContent,

        ImageHover
    },
    data() {
        return {
            popupData: {},
            infoDescription: infoDescription()
        }
    },
    methods: {
        openModal(id) {
            this.popupData = this.infoDescription.find(data => data.id === id)
            this.$refs.popupContainer.open()
        },
        closeModal() {
            this.popupData = {}
        },
    }
}
</script>

<style scoped>
.footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;

    @media (min-width: 1024px) {
        max-height: 300px;
    }
}

.footer__banner {
    width: 30%;
    cursor: pointer;
    margin-top: auto;

    @media (min-width: 1024px) {
        width: auto;

        img {
            max-width: 200px;
        }
    }
}
</style>