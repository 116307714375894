<template>
    <img class="moving-img" :src="currentSrc" alt="Nocna Orkiestra Eksperymentalna"
        title="Nocna Orkiestra Eksperymentalna" @mouseover="updateSrc()" @mouseleave="updateSrc(src)">
</template>
<script>

export default {
    name: 'ImageHover',
    props: {
        src: {
            type: String,
            required: true
        },
        srcHover: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            currentSrc: this.getImage(this.src)
        }
    },
    methods: {
        updateSrc(src) {
            this.currentSrc = this.getImage(src ? src : this.srcHover);
        },
        getImage(src) {
            return require(`../assets/${src}`)
        }
    }
}
</script>

<style scoped></style>