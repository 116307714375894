<template>
    <div class="upcoming-events-content">

        <h2 class="upcoming-events-content__headline font-alt">Koncerty / Posłuchaj na żywo</h2>
        <div class="upcoming-events-content__main">to nie są normalne koncerty,
            to „Niekonwekcjonalia”<br>
            (tak, tworzymy również słowa)<br>
            Zbliżające się niekonwekcjonalia:
        </div>
        <div class="upcoming-events-content__events">
            <div>
                <div class="upcoming-events-content__heading">CYBER SPACE NIGHT SHOW</div>
                <br>
                już wkrótce on i offline
            </div>
            <div>
                <div class="upcoming-events-content__heading">POWER OF RYBNIK 2025</div>
                <br>
                terminy już wkrótce
            </div>
            <div>
                <div class="upcoming-events-content__heading">SPACE NIGHT SHOW TOUR</div>
                <br>
                terminy już wkrótce
            </div>
            <div>
                <div class="upcoming-events-content__heading">NOE INTERACTION</div>
                <br>
                terminy już wkrótce
            </div>
            <div>
                <div class="upcoming-events-content__heading">RYBNICKIE BETLEJEM</div>
                <br>
                już w te święta
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UpcomingEventsContent'
}
</script>
<style scoped lang="scss">
.upcoming-events-content {
    text-transform: none;
    height: max-content;
    overflow-y: auto;

    &__headline {
        text-align: center;
    }

    &__heading {
        text-transform: uppercase;
        color: #2397b4;
        font-weight: 600;
    }

    &__main {
        padding-bottom: 20px;
    }

    &__events {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 1024px) {
            flex-direction: row;
        }

        div {
            padding: 20px;
        }
    }
}
</style>