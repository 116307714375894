<template>
    <div class="contact">
        <span class="font-alt">Kontakt</span>
        <a href="mailto:nocnaorkiestra@gmail.com">nocnaorkiestra@gmail.com</a><br><br>
        management & concert booking<br><br>

        tel. <a href="tel:+48664764987">664 764 987</a> – Piotr Kotas,<br> <br>kontakt we
        wszystkich językach. (słucha, mówi
        raczej po polsku )<br>

        Mieszkasz pod latarnią? <br>Księżyc śpi w Twoim jeziorze? <br>Odziedziczyłeś starą
        fabrykę konserw?<br>
        Jeśli znasz w swojej okolicy przestrzeń w której moglibyśmy zagrać, skontaktuj się z nami!
        Nie ma miejsca które nie może stać się lepsze, dzięki magicznym dźwiękom, które już gotujemy specjalnie dla
        Ciebie.<br><br>

        <span class="font-alt">uwaga!</span>
        Ostatnie wolne terminy w sezonie 2024.<br>
        Tak jest! kto jak nie ty?<br>
        Kolejna rocznica wyremontowanego zamku?<br>
        Pierwszy koncert po objęciu dyrektorstwa w jednostce?<br>
        Juwenalia, związki partnerskie albo recydywa?<br>
        Zaskocz swoich poddanych.<br>
        <SocialMedia />
    </div>
</template>
<script>
import SocialMedia from './SocialMedia.vue'
export default {
    name: 'ContactVue',
    components: {
        SocialMedia
    },
}
</script>
<style scoped lang="scss">
.contact {
    text-transform: none;
    padding: 40px 0 20px;
}
</style>
  