<template>
    <Teleport to="html">
        <div v-if="isOpen" class="popup-container" :id="id" :class="{
            'popup-container--open': isOpen,
            'popup-container--fullWidth': fullWidth,
            'popup-container--keep-small': keepSmall,

        }">
            <template v-if="type === 'imageDescription'">
                <div class="popup-container__top" v-if="topImage && !!topImage.src">
                    <img class="popup-container__top-image" :src="getImage(topImage.src)"
                        alt="Nocna Orkiestra Eksperymentalna" :title="`Nocna Orkiestra Eksperymentalna, ${name}`">
                </div>
                <div class="popup-container__text">
                    <template v-if="text">
                        {{ text }}
                    </template>
                    <template v-else>
                        <slot />
                    </template>
                </div>
                <div class="popup-container__bottom" v-if="bottomImage && bottomImage.src">
                    <img class="popup-container__bottom-image" :src="getImage(bottomImage.src)"
                        alt="Nocna Orkiestra Eksperymentalna" :title="`Nocna Orkiestra Eksperymentalna, ${name}`">

                </div>
            </template>
            <template v-else>
                <slot />
            </template>
            <button class="popup-container__close" @click="close">
                <div class="popup-container__close-leftright"></div>
                <div class="popup-container__close-rightleft"></div>
            </button>
        </div>
    </Teleport>
</template>
<script>

export default {
    name: 'PopupContainer',
    props: {
        id: {
            type: String,
            required: false
        },
        type: {
            type: String,
            required: false
        },
        topImage: {
            type: Object,
            required: false
        },
        bottomImage: {
            type: Object,
            required: false
        },
        name: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: false
        },
        fullWidth: {
            type: Boolean,
            default() {
                return false
            }
        },
        keepSmall: {
            type: Boolean,
            default() {
                return false
            }
        },
    },
    data() {
        return {
            isOpen: false,
            bodyEl: document.querySelector('body'),
        }
    },
    emits: ['close'],
    methods: {
        open() {
            this.isOpen = true

            if (
                !this.bodyEl.classList.contains('popup-opened')) {
                this.bodyEl.classList.add('popup-opened')
            }
        },
        close() {
            this.isOpen = false
            this.bodyEl.classList.remove('popup-opened')
            this.$emit('close')
        },
        getImage(src) {
            return require(`../assets/${src}`);
        }
    }
}
</script>

<style scoped lang="scss">
.popup-container {
    z-index: 10000;
    background-color: rgba(5, 5, 5, 0.9);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    border-radius: 20px;
    width: 90vw;
    top: 10%;
    left: 5vw;
    box-sizing: border-box;

    @media (min-width: 768px) {
        left: 50%;
        width: auto;
        transform: translateX(-50%);
        min-width: 80vw;
        padding: 30px;
    }

    @media (min-width: 1440px) {
        min-width: 75vw;
    }

    @media (min-width: 1600px) {
        min-width: 65vw;
        max-width: 70vw;
    }

    &--fullWidth {
        min-width: 90vw;
        max-width: 90vw;

        @media (min-width: 768px) {
            min-width: 80vw;
            max-width: 80vw;
        }
    }

    &--keep-small {
        @media (min-width: 1024px) {
            min-width: 65vw;
            max-width: 65vw;
            top: 20%;
        }


        @media (min-width: 1600px) {
            min-width: 50vw;
            max-width: 50vw;
        }
    }
}

.popup-container__top {
    width: 100%;
    top: -50px;
    position: absolute;
    width: 130px;
    left: 50%;
    transform: translateX(-50%);
}

.popup-container__top img {
    margin: 0 auto;
    width: 100%;
}

.popup-container__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;

    @media (min-width: 1024px) {
        width: 25px;
        height: 25px;
    }
}


.popup-container__close-leftright {
    height: 2px;
    width: 20px;
    position: absolute;
    background-color: #2397b4;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: transform .3s ease-in;

    @media (min-width: 1024px) {
        width: 25px;
    }
}

.popup-container__text {
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    padding: 80px 20px 40px;
    text-align: center;
    font-weight: 500;

    @media (min-width: 768px) {
        font-size: 18px;
    }
}

.popup-container__bottom {
    margin: 0 auto;
    max-width: 40%;
}

.popup-container__bottom img {
    width: 100%;
}

.popup-container__close-rightleft {
    height: 2px;
    width: 20px;
    position: absolute;
    background-color: #2397b4;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: transform .3s ease-in;

    @media (min-width: 1024px) {
        width: 25px;
    }
}

.popup-container__close:hover .popup-container__close-leftright {
    transform: rotate(-45deg);
}

.popup-container__close:hover .popup-container__close-rightleft {
    transform: rotate(45deg);
}
</style>